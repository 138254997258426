<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="Sku" prop="skuId">-->
<!--                <a-input v-model="queryParam.skuId" placeholder="" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="Brand" prop="brandId">-->
<!--                <a-input v-model="queryParam.brandId" placeholder="" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="Product Model" prop="productModel">-->
<!--                <a-input v-model="queryParam.productModel" placeholder="" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="Marketing Name" prop="name">
                <a-input v-model="queryParam.name" placeholder="Search Product" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Brand" prop="brandId">
                <a-select
                  placeholder="-Brand-"
                  style="width: 100%"
                  showSearch
                  v-model="queryParam.brandId"
                  optionFilterProp="title"
                >
                  <a-select-option v-for="item in brandList" :key="item.id" :value="item.id" :title="item.name">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Product Type" prop="productTypeId">
                <a-select
                  placeholder="-Product Type-"
                  style="width: 100%"
                  showSearch
                  v-model="queryParam.productTypeId"
                  optionFilterProp="title"
                >
                  <a-select-option v-for="item in productTypeList" :key="item.id" :value="item.id" :title="item.name">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Status" prop="status">
                <a-select v-model="queryParam.status">
                  <a-select-option :key="1">Enable</a-select-option>
                  <a-select-option :key="0">Disable</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Tank Capacity" prop="brandId">
                <a-select
                  placeholder="-Tank Capacity-"
                  style="width: 100%"
                  showSearch
                  v-model="queryParam.tankSizeId"
                  optionFilterProp="title"
                >
                  <a-select-option v-for="item in TankSizeList" :key="item.id" :value="item.id" :title="item.name">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Activation" prop="activationType">
                <a-select v-model="queryParam.activationType">
                  <a-select-option :key="0">Button Activated</a-select-option>
                  <a-select-option :key="1">Inhale Activated</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>


            <!--              <a-col :md="8" :sm="24">-->
            <!--                <a-form-item label="产品信息" prop="userId">-->
            <!--                  <a-input v-model="queryParam.userId" placeholder="请输入产品信息" allow-clear/>-->
            <!--                </a-form-item>-->
            <!--              </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="默认为0 自定义尺寸 否则关联{zb_public_config.id}" prop="tankSizeId">-->
<!--                <a-input v-model="queryParam.tankSizeId" placeholder="请输入默认为0 自定义尺寸 否则关联{zb_public_config.id}" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">

<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="尺寸值" prop="tankSizeValue">-->
<!--                  <a-input v-model="queryParam.tankSizeValue" placeholder="请输入尺寸值" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="其他选项 否则关联{zb_public_config.id}" prop="otherOptionId">-->
<!--                  <a-input v-model="queryParam.otherOptionId" placeholder="请输入其他选项 否则关联{zb_public_config.id}" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="其他选项值" prop="otherOptionValue">-->
<!--                  <a-input v-model="queryParam.otherOptionValue" placeholder="请输入其他选项值" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />Search</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />Clear All</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['cigarette:sku:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['cigarette:sku:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['cigarette:sku:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['cigarette:sku:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />

      <a-row :gutter="[16,16]"  :loading="loading">
        <a-col :span="4" v-hasPermi="['cigarette:brandSku:add']">
          <div class="goodsDateAdd" @click="$refs.createForm.handleAdd()">
            <a-icon type="plus" class="goodsDateAdd-icon" style="font-size: 50px;" />
            <div class="goodsDateAdd-title">Create SKU</div>
          </div>
        </a-col>
        <template v-for="(item,index) in list">
          <a-col :span="4" v-if="item.status !== 2">
            <div class="goodsDateInfo" :style="[{
            background:item.status === 0 ? '#f8f8f8':'#fff'
          }]">
              <div class="goodsDateInfo-absolute">
<!--                <a-icon type="play-circle" theme="twoTone" @click.stop="changeStatus(item,1)" v-if="item.status === 0" style="font-size: 28px;" />-->
<!--                <a-icon type="pause-circle" theme="twoTone" @click.stop="changeStatus(item,0)" v-else style="font-size: 28px;" />-->
<!--                              <a-icon type="eye" theme="twoTone" style="font-size: 28px;margin-left: 5px" />-->
<!--                <a-icon type="edit" v-hasPermi="['cigarette:brandSku:edit']" @click="$refs.createForm.handleUpdate(item, undefined)" theme="twoTone" style="font-size: 28px;margin-left: 5px" />-->
              </div>
              <div class="goodsDateInfo-GoodsDate">
                <div class="goodsDateInfo-GoodsDate-flex">
                  <img class="goodsDateInfo-GoodsDate-flex-img" :src="item.logo" />
                  <div class="goodsDateInfo-GoodsDate-flex-name">{{ item.name }}</div>
                </div>
              </div>
              <div class="goodsDateInfo-bottom">
                <div class="goodsDateInfo-bottom-flex">
                  <a-button type="primary" @click="gotoFlavorPage(item)" v-hasPermi="['cigarette:brandSku:flavor']" v-if="item.productType === 0">Flavor</a-button>
                  <a-button type="primary" @click="gotoCurves(item)" v-hasPermi="['cigarette:brandSku:curves']">Curves</a-button>
                  <a-button type="primary" v-hasPermi="['cigarette:brandSku:batch']">Batch</a-button>
                </div>
              </div>
            </div>
          </a-col>
        </template>

      </a-row>
      <a-empty :description="false" style="margin-top: 100px;margin-bottom: 100px" v-if="userInfo.roleList[0].roleKey == 'platform' && list.length === 0" />
<!--{{userInfo.roleList[0].roleKey == 'platform'}}-->
      <!-- 数据展示
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
<!--      <a-table-->
<!--        :loading="loading"-->
<!--        :size="tableSize"-->
<!--        rowKey="id"-->
<!--        :columns="columns"-->
<!--        :data-source="list"-->
<!--        :pagination="false"-->
<!--        :bordered="tableBordered"-->
<!--      >-->
<!--        <span slot="createTime" slot-scope="text, record">-->
<!--          {{ parseTime(record.createTime) }}-->
<!--        </span>-->
<!--        <span slot="operation" slot-scope="text, record">-->
<!--           <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:brandSku:flavor']">-->
<!--            <a-icon type="eye" />Flavor-->
<!--          </a>-->
<!--           <a-divider type="vertical" v-hasPermi="['cigarette:brandSku:curves']" />-->
<!--           <a @click="gotoCurves" v-hasPermi="['cigarette:brandSku:curves']">-->
<!--            <a-icon type="eye" />Curves-->
<!--          </a>-->

<!--          <a-divider type="vertical" v-hasPermi="['cigarette:brandSku:batch']" />-->
<!--           <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:brandSku:batch']">-->
<!--            <a-icon type="eye" />Batch-->
<!--          </a>-->
<!--        </span>-->
<!--      </a-table>-->
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `${total} results`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageSku,listSku, delSku } from '@/api/cigarette/sku'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {listBrand} from "@/api/cigarette/brand";
import {listConfig} from "@/api/cigarette/config";
import Template from "@/views/sms/template";

export default {
  name: 'Sku',
  components: {
    Template,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        skuId: null,
        brandId: null,
        productModel: null,
        name: null,
        status: null,
        content: null,
        userId: null,
        type: null,
        tankSizeId: null,
        tankSizeValue: null,
        otherOptionId: null,
        otherOptionValue: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '规格id',
        //   dataIndex: 'skuId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '品牌id',
        //   dataIndex: 'brandId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Product Model',
          dataIndex: 'productModel',
          align: 'center'
        },
        {
          title: 'Marketing Name',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: 'Status',
          dataIndex: 'status',
          align: 'center',
          customRender(t,r) {
            if(r.status == 1) {
              return 'Enable'
            } else if(r.status == 2) {
              return 'Draft'
            } else {
              return 'Disable'
            }
          }
        },
        {
          title: 'Product Introduction',
          dataIndex: 'content',
          align: 'center'
        },
        // {
        //   title: '产品信息',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Available Compatibility',
          dataIndex: 'type',
          align: 'center',
          customRender(t,r) {
            if(r.status == 1) {
              return ' OMNI Hub'
            } else {
              return 'OMNI NFC'
            }
          }
        },
        // {
        //   title: '默认为0 自定义尺寸 否则关联{zb_public_config.id}',
        //   dataIndex: 'tankSizeId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Available Tank Size',
          dataIndex: 'tankSizeValue',
          align: 'center'
        },
        // {
        //   title: '其他选项 否则关联{zb_public_config.id}',
        //   dataIndex: 'otherOptionId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Other Option',
          dataIndex: 'otherOptionValue',
          align: 'center'
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      brandList:[],
      TankSizeList:[],
      productTypeList: []
    }
  },
  created () {
    console.log('this.$route.params.skuId')
    console.log(this.$route.query.skuId)
    this.queryParam.skuId = this.$route.query.skuId || ''
    this.queryParam.brandId = this.$route.query.brandId || ''
    this.getList();
    this.getBrandListData();
  },
  mounted() {
    // console.log('this.$route.params.skuId')
    // console.log(this.$route)
    // this.queryParam.skuId = this.$route.params.skuId || ''
    // this.getList()
  },
  computed: {
    userInfo() {
      return this.$store.getters.info;
    }
  },
  watch: {
  },
  methods: {
    getBrandListData() {
      listConfig({
        type: 1,
        status: 1
      }).then(res => {
        if (res.success) {
          this.TankSizeList = res.data;
        }
      })
      listConfig({
        type: 6,
        status: 1
      }).then(res => {
        if (res.success) {
          this.productTypeList = res.data;
        }
      })
      listBrand({
        brandStatus:1,
        status:1
      }).then(res => {
        if(res.success) {
          this.brandList=res.data;
        }
      })
    },
    gotoCurves(record) {
      // this.$router.push("myCurves")
      this.$router.push({
        path:'/clientbrand/curves',
        query:
          {
            skuId: record.id
          }
      })
    },
    gotoFlavorPage(item) {
      this.$router.push({
        path: '/clientbrand/flavor',
        query:
          {
            skuId: item.id
          }
      })
    },
    /** 查询新-产品规格信息列表 */
    getList () {
      this.loading = true
     pageSku(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        skuId: undefined,
        brandId: undefined,
        productModel: undefined,
        name: undefined,
        status: undefined,
        content: undefined,
        userId: undefined,
        type: undefined,
        tankSizeId: undefined,
        tankSizeValue: undefined,
        otherOptionId: undefined,
        otherOptionValue: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delSku(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/brand-sku/export', {
            ...that.queryParam
          }, `新-产品规格信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.goodsDateAdd {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 8px;
  flex-direction: column;
}
.goodsDateAdd:hover {
  border: 1px solid blue;

  .goodsDateAdd-icon {
    color:blue;
  }

  .goodsDateAdd-title {
    color: blue;
  }
}
.goodsDateInfo {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 8px;
  flex-direction: column;
}

.goodsDateInfo-GoodsDate-flex-img {
  height: 200px;
  width: 80%;
}

.goodsDateInfo-GoodsDate-flex-name {
  width: 80%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.goodsDateInfo-absolute {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
  z-index: 2;
}
.goodsDateInfo-GoodsDate {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.goodsDateInfo-GoodsDate-flex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.goodsDateInfo-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  display: none;
}

.goodsDateInfo-bottom-flex {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}
.goodsDateInfo:hover {
  border: 1px solid blue;
  .goodsDateInfo-absolute {
    display: block;
  }

  .goodsDateInfo-bottom {
    display: block;
  }
}
</style>


